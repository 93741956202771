import { queryMenus } from '@/api/user'
import { message } from 'ant-design-vue'
import { LayoutBlock, LayoutUser, LayoutBase, LayoutMicro, LayoutIframe } from '@/layouts'
import { generator } from './handler'
import storage from 'store'
// import mall from './mode'
import common from './common'

// 前端路由表
export const routeComponents = {
  LayoutBlock,
  LayoutUser,
  LayoutBase,
  LayoutMicro,
  LayoutIframe,
  // 自定义的动态路由 这里有多少个路由在这里注册
  company: () => import('@/views/user/login'),
  permission: () => import('@/views/user/login'),
  role: () => import('@/views/user/login'),
  staff: () => import('@/views/user/login'),
  message: () => import('@/views/user/login'),
  overview: () => import('@/views/user/login'),
  member: () => import('@/views/user/login'),
  // 商品中心
  management: () => import('@/views/user/login'), //产品列表
  // management_gifts: () => import('@/views/commodity/management'),
  management_gifts: () => import('@/views/user/login'),//产品赠送
  service: () => import('@/views/user/login'),//服务列表
  service_gifts: () => import('@/views/user/login'),//服务赠送
  plan: () => import('@/views/user/login'),//方案列表
  plan_gifts: () => import('@/views/user/login'),//赠送方案
  storedvaluecard: () => import('@/views/user/login'),//储值卡列表


  type: () => import('@/views/user/login'),
  typeFinance: () => import('@/views/user/login'),
  typeStore: () => import('@/views/user/login'),
  typeMall: () => import('@/views/user/login'),

  brand: () => import('@/views/user/login'),
  brandsinfos: () => import('@/views/user/login'),
  // 临时合伙人菜单
  partnerMenu: () => import('@/views/user/login'), //概况
  partnerMenuLs: () => import('@/views/user/login'),//概况
  partnerMenuSetting: () => import('@/views/user/login'), //设置
  partnerMenuSettingAdd: () => import('@/views/user/login'), //设置
  partnerMenuUser: () => import('@/views/user/login'),//账户
  partnerMenuEarnings: () => import('@/views/user/login'), //收益
  partnerMenuEarningsLs: () => import('@/views/user/login'), //收益ls 
  partnerMenuFile: () => import('@/views/user/login'), //档案


  bonus: () => import('@/views/user/login'), //收益  分红
  commission: () => import('@/views/user/login'), //收益 佣金

  bonusLs: () => import('@/views/user/login'), //收益  分红 ls
  commissionLs: () => import('@/views/user/login'), //收益 佣金ls

  partnerMenuUserLs: () => import('@/views/user/login'),
  partnerMenuFileLs: () => import('@/views/user/login'),


  // BI报表
  RegionalSettings: () => import('@/views/user/login'), //区域设置 /reportForms/regionalSettings
  ReportFieldConfiguration: () => import('@/views/user/login'),//报表字段配置/reportForms/reportFieldConfiguration
  OperatingStatement: () => import('@/views/user/login'),//营业报表 /reportForms/operatingStatement
  UnconsumptionStatistics: () => import('@/views/user/login'), //未消耗统计 /reportForms/unconsumptionStatistics
  CustomerConsumptionStatistics: () => import('@/views/user/login'),//客户消费统计 /reportForms/customerConsumptionStatistics
  ItemStatistics: () => import('@/views/user/login'), //品项统计 /reportForms/itemStatistics
  ItemSalesRanking: () => import('@/views/user/login'),//品项销售排名 /reportForms/itemSalesRanking
  ItemSalesStatistics: () => import('@/views/user/login'),//品项销售统计 /reportForms/itemSalesStatistics
  shopUseStatistics: () => import('@/views/user/login'),//品项销售统计 /reportForms/itemSalesStatistics

  // 连锁商城
  malloverview: () => import('@/views/user/login'),//概况  /mall/malloverview
  mallshoplist: () => import('@/views/user/login'),//商城列表  /mall/mallshoplist
  mallgrounding: () => import('@/views/user/login'),//商城列表 已上架  /mall/mallshoplist/mallgrounding
  malloffshelf: () => import('@/views/user/login'),//商城列表 已下架  /mall/mallshoplist/mallgrounding
  Mallorder: () => import('@/views/user/login'),//商城订单	
  Saleslip: () => import('@/views/user/login'),//销售单列表	 	
  Selfpickupgoods: () => import('@/views/user/login'),//自提商品
  Verificationservice: () => import('@/views/user/login'),//核销服务
  AfterSales: () => import('@/views/user/login'),//售后
  Mallusers: () => import('@/views/user/login'),// 商城用户
  Malluserslist: () => import('@/views/user/login'),// 用户列表
  Userassets: () => import('@/views/user/login'),// 用户资产
  Mallsetting: () => import('@/views/user/login'),// 设置   
  Expresssettings: () => import('@/views/user/login'),// 快递设置
  PaymentConfiguration: () => import('@/views/user/login'),// 支付配置
  Operationmanagement: () => import('@/views/user/login'),//运营管理
  Applet: () => import('@/views/user/login'),//小程序配置
  //  \views\mall\operationmanagement
  universalPayConfig: () => import('@/views/user/login'),  // 通用支付配置
  shopPayConfig: () => import('@/views/user/login'),  // 门店支付配置

  // 内容管理
  CMS_Channel: () => import('@/views/user/login'),  // 频道管理
  CMS_Imagetext: () => import('@/views/user/login'),  // 图文管理
  CMS_Sourcematerial: () => import('@/views/user/login'),  // 素材管理	
}
// 动态生成菜单
export const generatorDynamicRoutes = () => {

  // return queryMenus().then(res => { 

  let obj = {
    id: 1,
    pid: 0,
    title: "/",
    icon: "meiye-routeicon-comment",
    path: "/",
    component: "LayoutBase",
    params: "",
    children: storage.get('menu_base')
  }
  let data = [obj]
  const invalidMenu = !data || !data.length || data[0].path !== '/'
  // 兼容后端接口数据返回不规范的情况
  if (invalidMenu) {
    message.error('菜单树接口返回的数据格式不规范')
    data = [{
      path: '/',
      component: 'LayoutBase',
      children: []
    }]
  }

  // const invalidMenu = !data || !data.length || data[0].path !== '/'
  // 兼容后端接口数据返回不规范的情况
  // if (invalidMenu) {
  //   message.error('菜单树接口返回的数据格式不规范')
  //   data = [{
  //     path: '/',
  //     component: 'LayoutBase',
  //     children: []
  //   }]
  // }
  // 将通用路由添加到根路由下面
  const rootRoute = data.find(item => item.path === '/') || {}
  if (rootRoute.children) {
    // rootRoute.children = rootRoute.children.concat(template, common)

    // rootRoute.children = rootRoute.children.concat(mall)
    rootRoute.children = rootRoute.children.concat(common)
  }

  const menus = generator(data, routeComponents)
  return menus
}
