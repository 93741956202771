<!--
 * @Version: 2.0
 * @Autor: YangKX
 * @Date: 2022-11-26 10:40:34
 * @LastEditors: YangKX
 * @LastEditTime: 2023-03-29 16:49:41
-->
<template>
  <div class="height100">
    <a-layout class="height100">
      <a-layout-content
        class="flex"
        :style="{ overflow: 'auto', background: '#F4F5F7' }"
      >
        <iframe
          v-if="src"
          class="flex-1"
          id="iframepage"
          :src="src"
          sandbox="allow-forms allow-popups allow-downloads
allow-same-origin
allow-scripts"
          frameborder="0"
        ></iframe>
      </a-layout-content>
    </a-layout>
  </div>
</template>

<script>
import storage from 'store'
export default {
  name: 'LayoutIframe',
  data() {
    return {
      src: '',
    }
  },
  created() {
    if (this.$route.query.nick_name == 'quanyika') {
      this.src = this.$route.query.url + '?authorization=' + this.$route.query.authorization
    } else {
      this.src = this.$route.query.url + '?authorization=' + storage.get('Access-Token')
    }
    // https://sandbox.zsff.meirenji.vip/admin/uumlogin/in?authorization=
    // https://sandbox.zsff.meirenji.vip/admin/uumlogin/inuumlogin/in?authorization=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvc2FuZGJveC5pbnRlcmZhY2UuY2MubWVpcmVuamkudmlwXC9hcGlcL3YxXC9hdXRoXC9hZG1pbl9sb2dpbiIsImlhdCI6MTY5NDY3MzE0OSwiZXhwIjoxNjk0NzU5NTQ5LCJuYmYiOjE2OTQ2NzMxNDksImp0aSI6ImI4YmkwNzdYTUNpMXV3Wm0iLCJzdWIiOjQyOCwicHJ2IjoiNTUwOTgxMmU1OTRiOWYyOWE1ODhkYzkxMmM4N2Y1YmExYjY3MzZjNCJ9.dy0IU6t3zpU3id-N7Ma1em9miAauVUVG4ABamLDLGmQ"
    //  this.src =  'https://discuzq.huanlong.group/admin/home'
    // this.src = 'https://sandbox.zsff.meirenji.vip/admin/uumlogin/in?authorization=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvc2FuZGJveC5pbnRlcmZhY2UuY2MubWVpcmVuamkudmlwXC9hcGlcL3YxXC9hdXRoXC9hZG1pbl9sb2dpbiIsImlhdCI6MTY5NDY3MzE0OSwiZXhwIjoxNjk0NzU5NTQ5LCJuYmYiOjE2OTQ2NzMxNDksImp0aSI6ImI4YmkwNzdYTUNpMXV3Wm0iLCJzdWIiOjQyOCwicHJ2IjoiNTUwOTgxMmU1OTRiOWYyOWE1ODhkYzkxMmM4N2Y1YmExYjY3MzZjNCJ9.dy0IU6t3zpU3id-N7Ma1em9miAauVUVG4ABamLDLGmQ'
    // this.src = storage.get('global_menu_obj').url + '?authorization=' + storage.get('Access-Token')
    // + storage.get('global_menu_obj').path + '?authorization=' + storage.get('Access-Token')
    console.log('this.src :>> ', this.src);
  },
  mounted() {

  },
  methods: {},
}
</script>

<style lang="less" scoped></style>
