<template>
  <span
    class="flex align-item-center"
    style="display: inline-flex; cursor: default"
  >
    <a-dropdown
      placement="bottomCenter"
      overlayClassName="reset-mrj-header-avatar-overlay"
    >
      <span class="mrj-layout-avatar__wrap-comp">
        <span class="mrj-layout-avatar__wrap-comp-img cursor-pointer">
          <a-avatar
            :size="30"
            :src="currentUser.staff_avatar || '/static/imgs/default-avatar.png'"
          />
        </span>
      </span>
      <template v-slot:overlay>
        <a-menu :selected-keys="[]">
          <a-menu-item
            key="changepwd"
            @click="handleInitModal"
          >
            <i class="meiye-icon meiye-a-xiugaimimamoren"></i>
            <span>修改密码</span>
          </a-menu-item>
          <a-menu-item
            key="logout"
            @click="handleLogout"
          >
            <i class="meiye-icon meiye-tuichudenglu"></i>
            <span>退出登录</span>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
    <a-modal
      :closable="false"
      footer=""
      v-model="visible"
      width="416px"
      centered
    >
      <div class="flex-c">
        <a-icon
          class="circle-class"
          type="exclamation-circle"
          theme="filled"
        />
        <span class="title"> 提示 </span>
      </div>
      <div class="text"> 您确认登出系统吗? </div>
      <div class="ant-modal-confirm-btns right">
        <button
          type="button"
          class="ant-btn"
          @click="visible = false"
        >取消</button>
        <button
          type="button"
          class="ant-btn ant-btn-primary ml-16"
          @click="hideModal"
        >确定</button>
      </div>
    </a-modal>
    <HandlePwd ref="HandlePwd" />
  </span>
</template>

<script>
import storage from 'store'
// import { Modal } from 'ant-design-vue'
import HandlePwd from './handlePwd'
const colorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae']
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'AvatarDropdown',
  components: { HandlePwd },
  props: {
    currentUser: {
      type: Object,
      default: () => null,
    },
    menu: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showMask: false,
      visible: false,
    }
  },
  computed: {
    color() {
      return colorList[Math.floor(Math.random() * colorList.length)]
    },
  },
  methods: {
    // ...mapActions(['logout']),
    onLogout() {
      localStorage.clear()
      this.$message.success('登出成功')
      location.href = `${process.env.VUE_APP_ROUTER_BASE_URL}user/login`
    },
    handleInitModal({ key }) {
      if (key == 'changepwd') {
        this.$nextTick(() => {
          const userInfo = storage.get('globalUserInfo')
          this.$refs.HandlePwd.initModal({ isFirst: false, userInfo })
        })
      }
    },
    handleLogout(e) {
      this.visible = !this.visible
    },
    hideModal() {
      localStorage.clear()
      this.onLogout()
      // return this.$store.dispatch('Logout').then(() => {
      //   this.$router.push({ name: 'login' })
      // })
    },
  },
}
</script>

<style lang="less" scoped>
.meiye-icon {
  font-size: 16px;
  margin-right: 4px;
}
.ant-pro-drop-down {
  /deep/ .action {
    margin-right: 8px;
  }
  /deep/ .ant-dropdown-menu-item {
    min-width: 160px;
  }
}
/deep/ .ant-dropdown-menu-item {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  padding: 5px 8px;
  img {
    margin-top: -0.5px;
    margin-right: 5px;
    width: 12px;
    height: 12px;
  }
}
.mrj-layout-avatar__wrap-comp {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mrj-layout-avatar__wrap-comp .mrj-layout-avatar__wrap-comp-img {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  line-height: 64px;
  border-radius: 50%;
  // background: linear-gradient(170deg, #F6FCFF 0%, #DBE9F6 100%);
  border: 1px solid transparent;
  // & .ant-avatar {
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  // }
}
// .mrj-layout-avatar__wrap-comp .mrj-layout-avatar__wrap-comp-img:hover,
// .mrj-layout-avatar__wrap-comp .mrj-layout-avatar__wrap-comp-img:active {
//   border: 1px solid @topnavavaborderoutColor;
// }
.mrj-layout-avatar__wrap-comp {
  position: relative;
  & .ant-avatar {
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid @topnavavaborderColor;
  }
  & .ant-avatar:hover {
    border: 1px solid @topnavavaborderColor;
  }
  & .ant-avatar:active {
    border: 1px solid @topnavavaborderColor;
  }
  & .mrj-layout-avatar__wrap-comp-img:hover {
    & .fuck__zhezhao-mask {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 8;
      width: calc(100% - 0px);
      height: calc(100% - 0px);
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.63);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 14px;
      border: 1px solid #334b9b;
    }
  }
  & .mrj-layout-avatar__wrap-comp-img:active {
    & .fuck__zhezhao-mask {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 8;
      width: calc(100% - 0px);
      height: calc(100% - 0px);
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 14px;
      border: 1px solid #253772;
      user-select: none;
    }
  }
}
.ant-dropdown-menu-item > .anticon:first-child {
  margin-right: 5px;
  font-size: 11.5px;
  min-width: auto;
}
.ant-dropdown-menu-item {
  // color: #9FA2AF;
  margin: 0 4px;
  // color: @fontColor3;
  font-size: 14px;
  &:hover {
    background: #ecf2fe;
    border-radius: 2px;
  }
}
/deep/.ant-modal-body {
  padding: 28px 26px 24px 40px;
  .flex-c {
    display: flex;
    align-items: center;
  }
  .circle-class {
    color: #faad14;
    margin-right: 8px;
    font-size: 18px;
  }
  .title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #33323f;
  }
  .text {
    margin-left: 1px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #848795;
    line-height: 22px;
    margin-top: 8px;
  }
  .right {
    margin-top: 30px;
    justify-content: flex-end;
    display: flex;
    .ml-16 {
      margin-left: 16px;
    }
  }
}
</style>
