<template>
  <div class="layout-user_box">
    <div class="layout-user_top">
      <div class="layout-user_topmain">
        <div class="layout-user_logo">
          <img src="../assets/images/meiyelogo.png" alt="">
        </div>
        <div class="layout-user_links">
          <!-- <a
            class="height100 flex align-item-center cursor-pointer"
            href="https://www.baidu.com"
            target="_blank"
            style="margin: 0 18px; color: #848795"
          >帮助与支持</a> -->
          <!-- <a
            class="height100 flex align-item-center cursor-pointer"
            href="https://www.baidu.com"
            target="_blank"
            style="margin: 0 18px; color: #848795;font-size: 12px;"
          >下载APP</a> -->
          <a class="height100 flex align-item-center cursor-pointer" href="/download"
            style="margin: 0 18px; color: #848795;font-size: 12px;">下载APP</a>
          <!-- <a-button
            style="margin-left:16px;width:94px;"
            class=""
            type="primary"
          >回到首页</a-button> -->
        </div>
      </div>
    </div>
    <div class="layout-user flex-1">
      <div class="layout-user__body flex-1">
        <router-view />
      </div>
      <nav-footer class="footer" />
    </div>
  </div>
</template> 
<script>
import { NavFooter } from '@/components'
export default {
  name: 'layout-user',
  components: {
    NavFooter
  }
}
</script>

<style lang="less" scoped>
.layout-user_box {
  width: 100%;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.layout-user {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  // background: rgb(237, 240, 245) url("../assets/images/bg-user.svg") 0 0 no-repeat;
  background: url("../assets/images/loginbg.png") no-repeat;
  background-size: cover;

  &__body {
    width: 410px;
    margin: 0 auto 0;

    // min-height: calc(100vh - 85px);
    display: flex;
    // align-items: center;
    // justify-content: center;
  }
}

.layout-user_top {
  width: 100%;
  height: 63px;
  border-bottom: 1px solid #cbd6df;
  background: #ffffff;
  background: repeating-linear-gradient(to top, #f1f4f7, #fff);

  .layout-user_topmain {
    // width: 1200px;
    width: 100%;

    height: 63px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    justify-content: space-around;

    .layout-user_logo {
      width: 142px;
      height: 38px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .layout-user_links {
      display: flex;
      align-items: center;
      height: 100%;

      a:hover {
        color: #33323f !important;
        border-bottom: 1px solid @btn5mainborderDColor;
      }

      a {
        text-decoration: none;
        border-bottom: 1px solid transparent;
      }
    }
  }
}

.footer {
  padding: 0;
  margin: 14px 0 16px;
  font-size: 14px;
  // margin: 0px 0 24px;
}</style>
