<template>
  <div class="box" v-if="boxshow">
    <a-tabs type="card" :activeKey="activeTabsKey" :style="{ width: '100%' }" class="mrj-layout-tabs  2222222222"
      :class="istabsShow ? 'mrj-layout-tabs_hide' : ''" @change="handleTabsClick" v-model="activeTabsKey"
      v-if="menuList && menuList.length">
      <template v-for="it in menuList">
        <a-tab-pane class="add" v-if="true" :key="it.title">
          <span slot="tab">
            <span class="inline-block" :class="activeTabsKey == it.title ? 'tabsCircleA' : 'tabsCircle'" :style="{
              width: '3px',
              height: '3px',
              marginBottom: '2.8px'
            }"></span>
            {{ it.title }}
          </span>
          <!-- <component
            class="temp1_com"
            :is="routeComponents[it.fullPath] || viewNotFound"
            :activeTabsKey="activeTabsKey"
          ></component> -->
          <router-view />
        </a-tab-pane>
      </template>
    </a-tabs>
    <div v-else class="box2 height100">
      <!-- <component
        class="temp2_com"
        :is="routeComponents[activeTabsPath]"
        v-if="activeTabsPathflag"
      ></component> -->
      <router-view />
    </div>

  </div>
</template>

<script>
// import microApp from '@micro-zoe/micro-app'
import storage from 'store'
import Msg from '@/components/mrj-menu/msg'
import { mapState } from 'vuex'
export default {
  inject: ['reload'],
  computed: {
    activeName() {
      const { fullPath } = this.$route
      return fullPath.split('?nick_name')[0]
    },
    ...mapState({
      routeComponents: state => state.menu.components
    }),
    // 404 页面
    viewNotFound() {
      return this.routeComponents['/*']
    }
    // menuList() {
    //   return storage.get('menuBar')
    // }
  },
  data() {
    return {
      boxshow: true,
      menuList: [],
      activeTabsKey: storage.get('activeTabsKey') || '',
      activeTabsPath: '',//没有子项 的页面
      istabsShow: false,
      activeTabsPathflag: true,
      isfouthSHow: false,//是否显示三级页签 
      currentRoute: {
        path: '',
        fullPath: '',
        meta: {},
        query: {},
        params: {},
        reload: false
      },
    }
  },
  created() {
    let arr = storage.get('seconderyMenuObj2') ? storage.get('seconderyMenuObj2') : []
    this.menuList = arr.children ? arr.children : []
    if (arr.children && arr.children.length) {
      this.currentRoute = arr.children[0]
      if (!storage.get('activeTabsKey')) {
        this.activeTabsKey = arr.children ? arr.children[0].title : arr.title
      }
      if (this.activeTabsKey == "合伙人佣金") {
        this.activeTabsKey = undefined
      }
      if (arr.children[0].title == '合伙人佣金' || arr.title == '权益卡' || arr.title == '制券中心' || arr.menu_title == '制券中心') {
        // this.boxshow = false
        // return false

        this.menuList = []
      }
    } else {
      // debugger
      this.activeTabsPath = arr.fullPath ? arr.fullPath : arr.fullpath
      console.log(this.routeComponents[this.activeTabsPath]);
      // this.activeTabsPath = '/reportForms/operatingStatement' 
    }
  },
  methods: {
    handleTabsClick(title) {
      console.log('title :>> ', title)
      let it = this.menuList.find((it) => it.title.includes(title))
      console.log('it :>> ', it)
      this.activeTabsKey = title
      storage.set('activeTabsKey', title)
      this.$router.push({
        path: it.fullPath
      })
    },

    // 路由切换时新增标签页
    getPanes() {
      const { path, fullPath, meta, query, params, matched } = this.$route

      if (this.menulist && this.menulist.length) {
        this.activeTabsKey = meta.title
      } else {
      }

    },
    reloads() {
      this.activeTabsPathflag = false
      this.$nextTick(() => (this.activeTabsPathflag = true))
    }
  },
  mounted() {
    var _this = this
    Msg.$on('getpathname', function (e) {
      console.log(e, 'getpathname');
      if (e.children && e.children.length) {
        e.children.forEach(el => {
          el.fullPath = `${e.fullPath}/${el.path}`
        })
      }
      _this.menuList = e.children ? e.children : []
      // _this.menuList[0].reload = false
      if (e.children && e.children.length) {
        _this.currentRoute = e.children[0]
        _this.activeTabsKey = e.children ? e.children[0].title : e.title
        storage.set('activeTabsKey', _this.activeTabsKey)// 为了回显三级页签用的
        _this.boxshow = false
        _this.$nextTick(() => (_this.boxshow = true))
        _this.reload()
        Msg.$emit('changeRouterview', true)
      } else {
        _this.activeTabsPath = e.fullPath
        _this.reloads()
        console.log(_this.routeComponents);
        console.log(_this.routeComponents[_this.activeTabsPath]);
        Msg.$emit('changeRouterview', true)
      }
      // console.log(_this.routeComponents[e.children[0].parentpath]);
      // console.log(this.routeComponents);
    })
    Msg.$on('changethirdaryShow', function (e) {
      //  
      _this.istabsShow = !e
      let a = document.querySelector('.mrj-layout-tabs>.ant-tabs-bar') || ''
      if (!e) {
        if (a) {
          document.querySelector('.mrj-layout-tabs>.ant-tabs-bar').style.display = 'none'
        }

      } else {
        if (a) {
          document.querySelector('.mrj-layout-tabs>.ant-tabs-bar').style.display = 'block'
        }

      }
    })


  },
  watch: {
    $route() {
      let _this = this
      _this.boxshow = false
      _this.$nextTick(() => (_this.boxshow = true))
    },
  }
}
</script>

<style lang="less" scoped>
.box {
  height: 100%;

  .mrj-layout-tabs:not(.default_tabs) {
    // height: 100%;
    // display: table;
    // margin-top: 2px;
    // padding-left: 30px;
    // padding-top: 23px;
    //   height: 49px;
    background: #f4f7f7;
    height: calc(100vh - 52px);

    // &:not(.ant-tabs-nav) {
    //   border-bottom: 1px solid #D9DDE4;
    // }
    // overflow: auto;
    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: 0;
      left: 0;
      width: 100%;
      height: 0.8px;
      top: 47.8px;
      background: @navborderColor;
      opacity: 1;
    }

    & /deep/ .ant-tabs-bar // ,

    // & /deep/ .ant-tabs-tab
      {
      height: 49px !important;
      line-height: 49px !important;
      padding: 20px 0 0 30px;
      background: #fff !important;
      margin: 0px;
      border-bottom: 1px solid #d3dedc;
    }

    & /deep/ .ant-tabs-tab {
      height: 29px !important;
      line-height: 29px !important;
      border: none !important;
      background: transparent !important;
      font-size: 15px !important;
    }

    &/deep/.ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
    &/deep/.ant-tabs-tab-next.ant-tabs-tab-arrow-show {
      margin: 0 !important;
      height: 29px !important;
      font-weight: 600 !important;
      line-height: 29px !important;
      border: none !important;
      font-size: 15px !important;
      color: #5b5a62;
      background: transparent !important;
      // padding: 20px 0 0 30px;
      // background: #fff !important;
    }

    & /deep/ .ant-tabs-tab {
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: -2px;
        left: 0;
        right: 0;
        bottom: -0.5px;
        background: url(../../assets/images/tabs-unselected-1.png) no-repeat 0 0;
        background-size: 100% 100%;
        opacity: 1;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: -2;
        left: 0;
        width: 100%;
        height: 1px;
        bottom: 0px;
        background: rgb(244, 245, 247);
        background-size: 100% 100%;
        opacity: 1;
      }
    }

    & /deep/ .ant-tabs-tab-active {
      color: @baseColor11 !important;
      position: relative;
      z-index: 1;

      &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        top: -2px;
        left: 0;
        right: 0;
        bottom: -0.5px;
        background: url(../../assets/images/tabs-selected-1.png) no-repeat 0 0;
        background-size: 100% 100%;
        opacity: 1;
      }
    }
  }

  .mrj-layout-tabs_hide {
    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: 0;
      left: 0;
      width: 100%;
      height: 0px;
      top: 48px;
      background: @navborderColor;
      opacity: 1;
    }
  }

  /deep/ .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
    height: 49px;
  }

  .tabsCircleA {
    background: @navselectedpointColor;
  }

  .tabsCircle {
    background: @navpointColor;
  }
}

/deep/.ant-tabs .ant-tabs-top-content,
.ant-tabs .ant-tabs-bottom-content {
  width: 100%;
  height: 100%;
}

/deep/.ant-tabs-tabpane-active {
  height: 100%;
}

.temp1_com {
  height: 100%;
}

.box2 {
  // overflow-y: scroll;

  &::-webkit-scrollbar {
     width: 0;
     background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}
</style>
