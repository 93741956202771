// itemSalesRanking

let obj = {
  production: [
    // 财税 
    {
      url: 'https://sg.shenglisige.com/fms/',
      url_name: '/splitAccount/merchant-management/list',
      key: '16',
      icon: 'b-a-iconcebianlanchouticaishui',
      nick_name: 'caishui',
      name: '财税',
      type: 'secondary',
      permission: 'financial',
      show: true,
      menuBar: [{
        url: 'https://sg.shenglisige.com/fms/',
        url_name: '/splitAccount/merchant-management/list',
        // url_name: '',
        nick_name: 'caishui',
        icon: '',
        name: '汇付宝进件',
        show: true,
        permission: 'financial',
        hiddenTab: 1,
      },
      {
        url: 'https://sg.shenglisige.com/fms/',
        url_name: '/splitAccount/settlement-split-account/center',
        // url_name: '',
        nick_name: 'caishui',
        icon: '',
        name: '汇付宝结算',
        show: true,
        permission: 'financial',
        hiddenTab: 1,
      },
      {
        url: 'https://sg.shenglisige.com/fms/',
        url_name: '/splitAccount/transaction-flow/list',
        // url_name: '',
        nick_name: 'caishui',
        icon: '',
        name: '交易流水',
        show: true,
        permission: 'financial',
        hiddenTab: 1,
      },
      {
        url: 'https://sg.shenglisige.com/fms/',
        url_name: '/splitAccount/refund-review',
        // url_name: '',
        nick_name: 'caishui',
        icon: '',
        name: '退款审批',
        show: true,
        permission: 'financial',
        hiddenTab: 1,
      },
      ],
    },
    // 组织
    {
      url: 'https://sg.shenglisige.com/cc/',
      url_name: '/personnelfiles/list',
      key: '15',
      icon: 'b-yuangong1',
      nick_name: 'yuangong',
      name: '组织',
      type: 'secondary',
      permission: 'zuzhijiagou',
      show: true,
      menuBar: [{
        url: 'https://sg.shenglisige.com/cc/',
        url_name: '/personnelfiles/list',
        nick_name: 'yuangong',
        icon: 'b-yonghubiaoqian',
        name: '人事档案',
        show: true,
        permission: 'organization_user',
        // permission: 'personnelfiles',
        hiddenTab: 2,
      },],
    },
    //报表
    {
      url: 'https://sg.shenglisige.com/partner/',
      url_name: '/reportForms/regionalSettings',
      key: '22',
      icon: 'b-a-iconcebianlanchoutibaobiao',
      nick_name: 'sysbaobiao',
      name: '报表',
      type: 'secondary',
      permission: 'financial',
      show: true,
      menuBar: [{
        url: 'https://sg.shenglisige.com/partner/',
        url_name: '/reportForms/regionalSettings',
        // url_name: '',
        nick_name: 'sysbaobiao',
        icon: '',
        name: '区域设置',
        show: true,
        permission: 'financial',
        hiddenTab: 1,
      },
      {
        url: 'https://sg.shenglisige.com/partner/',
        url_name: '/reportForms/itemStatistics',
        // url_name: '',
        nick_name: 'sysbaobiao',
        icon: '',
        name: '品项统计',
        show: true,
        permission: 'financial',
        hiddenTab: 1,
      },
      {
        url: 'https://sg.shenglisige.com/partner/',
        url_name: '/reportForms/reportFieldConfiguration',
        // url_name: '',
        nick_name: 'sysbaobiao',
        icon: '',
        name: '报表字段配置',
        show: true,
        permission: 'financial',
        hiddenTab: 1,
      },
      {
        url: 'https://sg.shenglisige.com/partner/',
        url_name: '/reportForms/unconsumptionStatistics',
        // url_name: '',
        nick_name: 'sysbaobiao',
        icon: '',
        name: '未消耗统计',
        show: true,
        permission: 'financial',
        hiddenTab: 1,
      },
      {
        url: 'https://sg.shenglisige.com/partner/',
        url_name: '/reportForms/operatingStatement',
        // url_name: '',
        nick_name: 'sysbaobiao',
        icon: '',
        name: '营业报表',
        show: true,
        permission: 'financial',
        hiddenTab: 1,
      },
      {
        url: 'https://sg.shenglisige.com/partner/',
        url_name: '/reportForms/shopUseStatistics',
        // url_name: '',
        nick_name: 'sysbaobiao',
        icon: '',
        name: '门店使用系统统计',
        show: true,
        permission: 'financial',
        hiddenTab: 1,
      },
      {
        url: 'https://sg.shenglisige.com/partner/',
        url_name: '/reportForms/itemSalesStatistics',
        // url_name: '',
        nick_name: 'sysbaobiao',
        icon: '',
        name: '品项销售统计',
        show: true,
        permission: 'financial',
        hiddenTab: 1,
      },
      {
        url: 'https://sg.shenglisige.com/partner/',
        url_name: '/reportForms/ItemSalesRanking',
        // url_name: '',
        nick_name: 'sysbaobiao',
        icon: '',
        name: '品项销售排名',
        show: true,
        permission: 'financial',
        hiddenTab: 1,
      },
      ],
    },
    // 绩效 
    {
      url: 'https://sg.shenglisige.com/kpi/',
      url_name: '/salesPerformance/index/list',
      key: '17',
      icon: 'b-a-iconcebianlanchoutijixiao',
      nick_name: 'jixaio',
      name: '绩效',
      type: 'secondary',
      show: true,
      permission: 'kpi',
    },
    // 会员中心 =》用户
    {
      url: 'https://sg.shenglisige.com/uum/',
      url_name: '/userlist/list',
      key: '14',
      icon: 'b-a-iconcebianlanchoutiyonghu',
      nick_name: 'yonghu',
      name: '用户',
      type: 'secondary',
      permission: 'uum',
      show: true,
    },
    // 合伙人
    {
      url: 'https://sg.shenglisige.com/partner/',
      url_name: '/partner/partnerMenu',
      nick_name: 'hehuoren',
      key: '7',
      icon: 'https://attachment.shenglisige.com/images/icon/hhr.png',
      name: '合伙人JT',
      type: 'secondary',
      permission: 'partnerjt',
      show: true,
      menuBar: [{
        url: 'https://sg.shenglisige.com/partner/',
        url_name: '/partner/partnerMenu',
        // url_name: '',
        nick_name: 'hehuoren',
        icon: 'b-gaikuang1',
        name: '概况',
        type: 'secondary',
        show: true,
        permission: 'system',
        hiddenTab: 2,
      },
      {
        url: 'https://sg.shenglisige.com/partner/',
        url_name: '/partner/partnerMenuSetting',
        // url_name: '',
        nick_name: 'hehuoren',
        icon: 'b-hehuorenshezhi',
        name: '合伙人设置',
        type: 'secondary',
        show: true,
        permission: 'partnerjt_setting',
        hiddenTab: 2,
      },
      {
        url: 'https://sg.shenglisige.com/partner/',
        url_name: '/partner/partnerMenuUser',
        // url_name: '',
        nick_name: 'hehuoren',
        icon: 'b-hehuorenzhanghu',
        name: '合伙人账户',
        type: 'secondary',
        show: true,
        permission: 'system',
        hiddenTab: 2,
      },
      {
        url: 'https://sg.shenglisige.com/partner/',
        url_name: '/partner/partnerMenuEarnings',
        // url_name: '',
        nick_name: 'hehuoren',
        icon: 'b-hehuorenshouyi',
        name: '合伙人收益',
        type: 'secondary',
        show: true,
        permission: 'partnerjt_revenue',
        hiddenTab: 2,
      },
      {
        url: 'https://sg.shenglisige.com/partner/',
        url_name: '/partner/partnerMenuFile',
        // url_name: '',
        nick_name: 'hehuoren',
        icon: 'b-hehuorendangan',
        name: '合伙人档案',
        type: 'secondary',
        show: true,
        permission: 'partnerjt_archive',
        hiddenTab: 2,
      },
      ],
    },
    // 裂变派送
    {
      url: 'https://share.huanlong.cloud',
      url_name: '/partner/partnerMenu',
      nick_name: 'hehuoren',
      key: '7',
      icon: 'https://attachment.shenglisige.com/images/icon/hhr.png',
      name: '裂变派送',
      type: 'secondary',
      permission: 'partnerjt',
      show: true,
      menuBar: [{
        url: 'https://share.huanlong.cloud',
        url_name: '/managecenter/list',
        // url_name: '',
        nick_name: 'hehuoren',
        icon: 'b-gaikuang1',
        name: '活动管理',
        type: 'secondary',
        show: true,
        permission: 'system',
        hiddenTab: 2,
      },
      {
        url: 'https://share.huanlong.cloud',
        url_name: '/data/list',
        // url_name: '',
        nick_name: 'hehuoren',
        icon: 'b-hehuorenshezhi',
        name: '数据统计',
        type: 'secondary',
        show: true,
        permission: 'partnerjt_setting',
        hiddenTab: 2,
      },
      {
        url: 'https://share.huanlong.cloud',
        url_name: '/settings/list',
        // url_name: '',
        nick_name: 'hehuoren',
        icon: 'b-hehuorenzhanghu',
        name: '参数设置',
        type: 'secondary',
        show: true,
        permission: 'system',
        hiddenTab: 2,
      },
      ],
    },
    // 卡券中心
    {
      url: 'https://coupon.huanlong.cloud/',
      url_name: '/managecenter/list',
      nick_name: 'hehuoren',
      key: '7',
      icon: 'https://attachment.shenglisige.com/images/icon/hhr.png',
      name: '卡券中心',
      type: 'secondary',
      permission: 'partnerjt',
      show: true,
      menuBar: [{
        url: 'https://coupon.huanlong.cloud/',
        url_name: '/managecenter/list',
        // url_name: '',
        nick_name: 'hehuoren',
        icon: 'b-gaikuang1',
        name: '制券中心',
        type: 'secondary',
        show: true,
        permission: 'system',
        hiddenTab: 2,
      },
      {
        url: 'https://portal.huanlong.cloud/qyk/',
        url_name: '/managecenter/list',
        // url_name: '',
        nick_name: 'hehuoren',
        icon: 'b-hehuorenshezhi',
        name: '权益卡',
        type: 'secondary',
        show: true,
        permission: 'partnerjt_setting',
        hiddenTab: 2,
      },
      ],
    },
    // 运营中心
    {
      url: 'https://sandbox.b2.meirenji.vip/',
      url_name: '/business-app-operation/sales-catalogue',
      nick_name: 'appcatalogue',
      icon: 'b-APP',
      name: 'APP运营',
      type: 'secondary',
      show: true,
      permission: 'app',
      hiddenTab: 1,
    },
    // 社区
    {
      url: 'https://group.shenglisige.com/admin',
      url_name: '/home',
      key: 'shequ',
      icon: 'https://attachment.shenglisige.com/images/icon/shequ.png',
      nick_name: 'shequ',
      name: '社区',
      type: 'stair',
      permission: 'community_admin',
      show: true,
    },
    // 关怀
    {
      url: 'https://snr.emp.shenglisige.com/admin/',
      url_name: '/admin/login',
      key: '20',
      icon: 'b-guanai',
      nick_name: 'guanai',
      name: '关怀',
      type: 'stair',
      show: true,
      permission: 'love_admin',
    },
    // 产品
    {
      url: 'https://sg.shenglisige.com',
      url_name: '/main-part/product/product-list/list',
      key: '21',
      icon: 'b-chanpin1',
      nick_name: 'chanpinlb',
      name: '产品',
      type: 'secondary',
      permission: 'product',
      show: true,
      menuBar: [{
        url: 'https://sg.shenglisige.com',
        url: '/main-part/product/product-list/list',
        // url_name: '',
        nick_name: 'chanpinlb',
        icon: 'b-chanpinliebiao',
        name: '产品列表',
        show: true,
        permission: 'product',
        hiddenTab: 1,
      },
      {
        url: 'https://sg.shenglisige.com',
        url_name: '/main-part/product/cooperative/list',
        // url_name: '',
        nick_name: 'chanpinlb',
        icon: 'b-pinneiweihu',
        name: '合作商品',
        show: true,
        permission: 'product',
        hiddenTab: 1,
      },
      {
        url: 'https://sg.shenglisige.com',
        url_name: '/main-part/product/cate-maintain/list',
        // url_name: '',
        nick_name: 'chanpinlb',
        icon: 'b-pinneiweihu',
        name: '品类维护',
        show: true,
        permission: 'product',
        hiddenTab: 1,
      },
      {
        url: 'https://sg.shenglisige.com',
        url_name: '/main-part/product/specs-maintain/list',
        // url_name: '',
        nick_name: 'chanpinlb',
        icon: 'b-guigeweihu',
        name: '规格维护',
        show: true,
        permission: 'product_attrs',
        hiddenTab: 1,
      },
      {
        url: 'https://sg.shenglisige.com',
        url_name: '/main-part/product/product-tags/list',
        //url_name: '',
        nick_name: 'chanpinlb',
        icon: 'b-chanpinbiaoqian',
        name: '产品标签',
        show: true,
        permission: 'product_tags',
        hiddenTab: 1,
      },
      {
        url: 'https://sg.shenglisige.com',
        url_name: '/main-part/product/value-card/list',
        // url_name: '',
        nick_name: 'chanpinlb',
        icon: 'b-chuzhikaguanli',
        name: '储值卡管理',
        show: true,
        permission: 'money_card',
        hiddenTab: 1,
      },
      {
        url: 'https://sg.shenglisige.com',
        url_name: '/main-part/product/material-manage/list',
        // url_name: '',
        nick_name: 'chanpinlb',
        icon: 'b-sucaiguanli',
        name: '素材管理',
        show: true,
        permission: 'product_sources',
        hiddenTab: 1,
      }
      ],
    },
    // 教育
    {
      url: 'http://zsff.shenglisige.com/admin/uumlogin/in',
      url_name: '/uumlogin/in',
      key: 'jiaoyu',
      icon: 'https://attachment.shenglisige.com/images/icon/jiaoyu.png',
      nick_name: 'jiaoyu',
      name: '教育',
      type: 'stair',
      permission: 'education_admin',
      show: true,
    },

  ],
  development: [
    // 财税
    {
      url: 'https://sandbox.system.meirenji.vip/fms/',
      url_name: '/splitAccount/merchant-management/list',
      key: '16',
      icon: 'b-a-iconcebianlanchouticaishui',
      nick_name: 'caishui',
      name: '财税',
      type: 'secondary',
      permission: 'financial',
      show: true,
      menuBar: [{
        url: 'https://sandbox.system.meirenji.vip/fms/',
        url_name: '/splitAccount/merchant-management/list',
        // url_name: '',
        nick_name: 'caishui',
        icon: '',
        name: '汇付宝进件',
        show: true,
        permission: 'financial',
        hiddenTab: 1,
      },
      {
        url: 'https://sandbox.system.meirenji.vip/fms/',
        url_name: '/splitAccount/settlement-split-account/center',
        // url_name: '',
        nick_name: 'caishui',
        icon: '',
        name: '汇付宝结算',
        show: true,
        permission: 'financial',
        hiddenTab: 1,
      },
      {
        url: 'https://sandbox.system.meirenji.vip/fms/',
        url_name: '/splitAccount/transaction-flow/list',
        // url_name: '',
        nick_name: 'caishui',
        icon: '',
        name: '交易流水',
        show: true,
        permission: 'financial',
        hiddenTab: 1,
      },
      {
        url: 'https://sandbox.system.meirenji.vip/fms/',
        url_name: '/splitAccount/refund-review',
        // url_name: '',
        nick_name: 'caishui',
        icon: '',
        name: '退款审批',
        show: true,
        permission: 'financial',
        hiddenTab: 1,
      },
      ],
    },
    // 组织
    {
      url: 'https://sandbox.system.meirenji.vip/cc/',
      url_name: '/personnelfiles/list',
      key: '15',
      icon: 'b-yuangong1',
      nick_name: 'yuangong',
      name: '组织',
      type: 'secondary',
      permission: 'zuzhijiagou',
      show: true,
      menuBar: [{
        url: 'https://sandbox.system.meirenji.vip/cc/',
        url_name: '/personnelfiles/list',
        nick_name: 'yuangong',
        icon: 'b-yonghubiaoqian',
        name: '人事档案',
        show: true,
        permission: 'organization_user',
        // permission: 'personnelfiles',
        hiddenTab: 2,
      },],
    },
    // 报表
    {
      url: 'https://sandbox.system.meirenji.vip/partner/',
      url_name: '/reportForms/regionalSettings',
      key: '22',
      icon: 'b-a-iconcebianlanchoutibaobiao',
      nick_name: 'sysbaobiao',
      name: '报表',
      type: 'secondary',
      permission: 'financial',
      show: true,
      menuBar: [
        {
          url: 'https://sandbox.system.meirenji.vip/partner/',
          url_name: '/reportForms/regionalSettings',
          // url_name: '',
          nick_name: 'sysbaobiao',
          icon: '',
          name: '区域设置',
          show: true,
          permission: 'financial',
          hiddenTab: 1,
        },
        {
          url: 'https://sandbox.system.meirenji.vip/partner/',
          url_name: '/reportForms/itemStatistics',
          // url_name: '',
          nick_name: 'sysbaobiao',
          icon: '',
          name: '品项统计',
          show: true,
          permission: 'financial',
          hiddenTab: 1,
        },
        {
          url: 'https://sandbox.system.meirenji.vip/partner/',
          url_name: '/reportForms/reportFieldConfiguration',
          nick_name: 'sysbaobiao',
          icon: '',
          name: '报表字段配置',
          show: true,
          permission: 'financial',
          hiddenTab: 1,
        },
        {
          url: 'https://sandbox.system.meirenji.vip/partner/',
          url_name: '/reportForms/unconsumptionStatistics',
          // url_name: '',
          nick_name: 'sysbaobiao',
          icon: '',
          name: '未消耗统计',
          show: true,
          permission: 'financial',
          hiddenTab: 1,
        },
        {
          url: 'https://sandbox.system.meirenji.vip/partner/',
          url_name: '/reportForms/operatingStatement',
          // url_name: '',
          nick_name: 'sysbaobiao',
          icon: '',
          name: '营业报表',
          show: true,
          permission: 'financial',
          hiddenTab: 1,
        },
        {
          url: 'https://sandbox.system.meirenji.vip/partner/',
          url_name: '/reportForms/shopUseStatistics',
          // url_name: '',
          nick_name: 'sysbaobiao',
          icon: '',
          name: '门店使用系统统计',
          show: true,
          permission: 'financial',
          hiddenTab: 1,
        },
        {
          url: 'https://sandbox.system.meirenji.vip/partner/',
          url_name: '/reportForms/customerConsumptionStatistics',
          // url_name: '',
          nick_name: 'sysbaobiao',
          icon: '',
          name: '客户消费统计',
          show: true,
          permission: 'financial',
          hiddenTab: 1,
        },
        {
          url: 'https://sandbox.system.meirenji.vip/partner/',
          url_name: '/reportForms/itemSalesStatistics',
          // url_name: '',
          nick_name: 'sysbaobiao',
          icon: '',
          name: '品项统计',
          show: true,
          permission: 'financial',
          hiddenTab: 1,
        },
        {
          url: 'https://sandbox.system.meirenji.vip/partner/',
          url_name: '/reportForms/ItemSalesRanking',
          // url_name: '',
          nick_name: 'sysbaobiao',
          icon: '',
          name: '品项销售排名',
          show: true,
          permission: 'financial',
          hiddenTab: 1,
        },
      ],
    },
    // 绩效
    {
      url: 'https://sandbox.system.meirenji.vip/kpi/',
      url_name: '/salesPerformance/index/list',
      key: '17',
      icon: 'b-a-iconcebianlanchoutijixiao',
      nick_name: 'jixaio',
      name: '绩效',
      type: 'secondary',
      show: true,
      permission: 'kpi',
    },
    // 会员中心 =》用户
    {
      url: 'https://sandbox.system.meirenji.vip/uum/',
      url_name: '/userlist/list',
      key: '14',
      icon: 'b-a-iconcebianlanchoutiyonghu',
      nick_name: 'yonghu',
      name: '用户',
      type: 'secondary',
      permission: 'uum',
      show: true,
    },
    // 合伙人
    {
      url: 'https://sandbox.system.meirenji.vip/partner/',
      url_name: '/partner/partnerMenu',
      nick_name: 'hehuoren',
      key: '7',
      icon: 'https://attachment.shenglisige.com/images/icon/hhr.png',
      name: '合伙人JT',
      type: 'secondary',
      permission: 'partnerjt',
      show: true,
      menuBar: [{
        url: 'https://sandbox.system.meirenji.vip/partner/',
        url_name: '/partner/partnerMenu',
        // url_name: '',
        nick_name: 'hehuoren',
        icon: 'b-gaikuang1',
        name: '概况',
        type: 'secondary',
        show: true,
        permission: 'system',
        hiddenTab: 2,
      },
      {
        url: 'https://sandbox.system.meirenji.vip/partner/',
        url_name: '/partner/partnerMenuSetting',
        // url_name: '',
        nick_name: 'hehuoren',
        icon: 'b-hehuorenshezhi',
        name: '合伙人设置',
        type: 'secondary',
        show: true,
        permission: 'partnerjt_setting',
        hiddenTab: 2,
      },
      {
        url: 'https://sandbox.system.meirenji.vip/partner/',
        url_name: '/partner/partnerMenuUser',
        // url_name: '',
        nick_name: 'hehuoren',
        icon: 'b-hehuorenzhanghu',
        name: '合伙人账户',
        type: 'secondary',
        show: true,
        permission: 'system',
        hiddenTab: 2,
      },
      {
        url: 'https://sandbox.system.meirenji.vip/partner/',
        url_name: '/partner/partnerMenuEarnings',
        // url_name: '',
        nick_name: 'hehuoren',
        icon: 'b-hehuorenshouyi',
        name: '合伙人收益',
        type: 'secondary',
        show: true,
        permission: 'partnerjt_revenue',
        hiddenTab: 2,
      },
      {
        url: 'https://sandbox.system.meirenji.vip/partner/',
        url_name: '/partner/partnerMenuFile',
        // url_name: '',
        nick_name: 'hehuoren',
        icon: 'b-hehuorendangan',
        name: '合伙人档案',
        type: 'secondary',
        show: true,
        permission: 'partnerjt_archive',
        hiddenTab: 2,
      },
      ],
    },
    //裂变派送 
    {
      url: 'https://sandbox.share.huanlong.cloud',
      url_name: '/partner/partnerMenu',
      nick_name: 'hehuoren',
      key: '7',
      icon: 'https://attachment.shenglisige.com/images/icon/hhr.png',
      name: '裂变派送',
      type: 'secondary',
      permission: 'partnerjt',
      show: true,
      menuBar: [{
        url: 'https://sandbox.share.huanlong.cloud',
        url_name: '/managecenter/list',
        // url_name: '',
        nick_name: 'hehuoren',
        icon: 'b-gaikuang1',
        name: '活动管理',
        type: 'secondary',
        show: true,
        permission: 'system',
        hiddenTab: 2,
      },
      {
        url: 'https://sandbox.share.huanlong.cloud',
        url_name: '/data/list',
        // url_name: '',
        nick_name: 'hehuoren',
        icon: 'b-hehuorenshezhi',
        name: '数据统计',
        type: 'secondary',
        show: true,
        permission: 'partnerjt_setting',
        hiddenTab: 2,
      },
      {
        url: 'https://sandbox.share.huanlong.cloud',
        url_name: '/settings/list',
        // url_name: '',
        nick_name: 'hehuoren',
        icon: 'b-hehuorenzhanghu',
        name: '参数设置',
        type: 'secondary',
        show: true,
        permission: 'system',
        hiddenTab: 2,
      },
      ],
    },
    // 卡券中心
    {
      url: 'https://sandbox.coupon.huanlong.cloud/',
      url_name: '/managecenter/list',
      nick_name: 'hehuoren',
      key: '7',
      icon: 'https://attachment.shenglisige.com/images/icon/hhr.png',
      name: '卡券中心',
      type: 'secondary',
      permission: 'partnerjt',
      show: true,
      menuBar: [{
        url: 'https://sandbox.coupon.huanlong.cloud/',
        url_name: '/managecenter/list',
        // url_name: '',
        nick_name: 'hehuoren',
        icon: 'b-gaikuang1',
        name: '制券中心',
        type: 'secondary',
        show: true,
        permission: 'system',
        hiddenTab: 2,
      },
      {
        url: 'https://sandbox.huanlong.cloud/qyk/',
        url_name: '/managecenter/list',
        // url_name: '',
        nick_name: 'hehuoren',
        icon: 'b-hehuorenshezhi',
        name: '权益卡',
        type: 'secondary',
        show: true,
        permission: 'partnerjt_setting',
        hiddenTab: 2,
      },
      ],
    },
    // 运营中心
    {
      url: 'https://sg.shenglisige.com',
      url_name: '/business-app-operation/sales-catalogue',
      nick_name: 'appcatalogue',
      icon: 'b-APP',
      name: 'APP运营',
      type: 'secondary',
      show: true,
      permission: 'app',
      hiddenTab: 1,
    },
    // 社区
    {
      url: 'https://discuzq.huanlong.group/admin',
      url_name: '/home',
      key: 'shequ',
      icon: 'https://attachment.shenglisige.com/images/icon/shequ.png',
      nick_name: 'shequ',
      name: '社区',
      type: 'stair',
      permission: 'community_admin',
      show: true,
    },
    // 关怀
    {
      url: 'https://snr.emp.shenglisige.com/admin/',
      url_name: '/admin/login',
      key: '20',
      icon: 'b-guanai',
      nick_name: 'guanai',
      name: '关怀',
      type: 'stair',
      show: true,
      permission: 'love_admin',
    },
    // 产品
    {
      url: 'https://sandbox.b2.meirenji.vip/',
      url_name: '/main-part/product/product-list/list',
      key: '21',
      icon: 'b-chanpin1',
      nick_name: 'chanpinlb',
      name: '产品',
      type: 'secondary',
      permission: 'product',
      show: true,
      menuBar: [{
        url: 'https://sandbox.b2.meirenji.vip/',
        url: '/main-part/product/product-list/list',
        // url_name: '',
        nick_name: 'chanpinlb',
        icon: 'b-chanpinliebiao',
        name: '产品列表',
        show: true,
        permission: 'product',
        hiddenTab: 1,
      },
      {
        url: 'https://sandbox.b2.meirenji.vip/',
        url_name: '/main-part/product/cooperative/list',
        // url_name: '',
        nick_name: 'chanpinlb',
        icon: 'b-pinneiweihu',
        name: '合作商品',
        show: true,
        permission: 'product',
        hiddenTab: 1,
      },
      {
        url: 'https://sandbox.b2.meirenji.vip/',
        url_name: '/main-part/product/cate-maintain/list',
        // url_name: '',
        nick_name: 'chanpinlb',
        icon: 'b-pinneiweihu',
        name: '品类维护',
        show: true,
        permission: 'product',
        hiddenTab: 1,
      },
      {
        url: 'https://sandbox.b2.meirenji.vip/',
        url_name: '/main-part/product/specs-maintain/list',
        // url_name: '',
        nick_name: 'chanpinlb',
        icon: 'b-guigeweihu',
        name: '规格维护',
        show: true,
        permission: 'product_attrs',
        hiddenTab: 1,
      },
      {
        url: 'https://sandbox.b2.meirenji.vip/',
        url_name: '/main-part/product/product-tags/list',
        //url_name: '',
        nick_name: 'chanpinlb',
        icon: 'b-chanpinbiaoqian',
        name: '产品标签',
        show: true,
        permission: 'product_tags',
        hiddenTab: 1,
      },
      {
        url: 'https://sandbox.b2.meirenji.vip/',
        url_name: '/main-part/product/value-card/list',
        // url_name: '',
        nick_name: 'chanpinlb',
        icon: 'b-chuzhikaguanli',
        name: '储值卡管理',
        show: true,
        permission: 'money_card',
        hiddenTab: 1,
      },
      {
        url: 'https://sandbox.b2.meirenji.vip/',
        url_name: '/main-part/product/material-manage/list',
        // url_name: '',
        nick_name: 'chanpinlb',
        icon: 'b-sucaiguanli',
        name: '素材管理',
        show: true,
        permission: 'product_sources',
        hiddenTab: 1,
      }
      ],
    },
    // 教育
    {
      url: 'https://sandbox.zsff.meirenji.vip/admin/uumlogin/in',
      url_name: '/uumlogin/in',
      key: 'jiaoyu',
      icon: 'https://attachment.shenglisige.com/images/icon/jiaoyu.png',
      nick_name: 'jiaoyu',
      name: '教育',
      type: 'stair',
      permission: 'education_admin',
      show: true,
    },


    // 系统
    {
      url: 'https://sandbox.system.meirenji.vip/cc/',
      url_name: '/hr-center/role-manager',
      key: '2',
      icon: 'b-xitong',
      name: '系统',
      nick_name: 'xitong',
      type: 'secondary',
      permission: 'system',
      show: true,
      menuBar: [{
        url: 'https://sandbox.system.meirenji.vip/cc/',
        url_name: '/hr-center/role-manager',
        nick_name: 'xitong',
        icon: 'b-quanxianguanli',
        name: '权限管理',
        type: 'secondary',
        show: true,
        permission: 'role',
        hiddenTab: 2,
      },
      {
        url: 'https://sandbox.system.meirenji.vip/cc/',
        url_name: '/versions/sysVersions/1',
        nick_name: 'xitong',
        icon: 'b-banbenweihu',
        name: '版本维护',
        type: 'secondary',
        show: true,
        permission: 'version_manage',
        hiddenTab: 2,
      },
      {
        url: 'https://sandbox.system.meirenji.vip/cc/',
        url_name: '/pushcenter/syspushcenter/1',
        nick_name: 'xitong',
        icon: 'b-tuisongzhongxin',
        name: '推送中心',
        type: 'secondary',
        show: true,
        permission: 'push_center',
        hiddenTab: 2,
      },
      {
        url: 'https://sandbox.system.meirenji.vip/cc/',
        url_name: '/management/sysmanagement',
        nick_name: 'xitong',
        icon: 'b-duanxinguanli',
        name: '短信管理',
        type: 'secondary',
        show: true,
        permission: 'sms_manage',
        hiddenTab: 2,
      },
      {
        url: 'https://sandbox.system.meirenji.vip/cc/',
        url_name: '/settings/list/1',
        nick_name: 'xitong',
        icon: 'b-canshushezhi',
        name: '参数设置',
        type: 'secondary',
        show: true,
        permission: 'opration_log',
        hiddenTab: 2,
      },
      {
        url: 'https://sandbox.system.meirenji.vip/cc/',
        url_name: '/operation/sysoperation',
        nick_name: 'xitong',
        icon: 'b-caozuorizhi',
        name: '操作日志',
        type: 'secondary',
        show: true,
        permission: 'opration_log',
        hiddenTab: 2,
      },
      ],
    },
    // 总览搜索页面 
    // https://sandbox.b2.meirenji.vip/search-result?keyworeds=1
  ]
}

export default obj