import request from '@/utils/request'

// 获取公司id
export function getCompany(data) {
  return request({
    method: 'post',
    url: '/api/cc/v1/auth/login_company',
    data
  })
}
// 手机号登录
export function login(data) {
  return request({
    method: 'post',
    url: '/api/cc/v1/auth/admin_sms_in_zt',
    data
  })
}
// 密码登录
export function pslogin(data) {
  return request({
    method: 'post',
    url: '/api/cc/v1/auth/admin_login_zt',
    // headerType: 'json',
    data
  })
}
// 获取短信验证码
export function getSmsCode(data) {
  return request({
    method: 'post',
    closeAutoTips: true,
    url: '/api/cc/v1/auth/send_sms',
    data
  })
}
// 获取历史操作菜单 /api/cc/v1/staff_operation_menu
export function getHistory(data) {
  return request({
    method: 'post',
    url: '/api/cc/v1/staff_operation_menu',
    data
  })
} 
// 记录员工操作记录
export function setHistory(data) {
  return request({
    method: 'post',
    url: '/api/cc/v1/staff_operation',
    data
  })
}  